<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-config', params: { entityType: entityType } }">
    <template #title>
      {{ translateTitleCase("admin.config.editTitle") }}
    </template>

    <template #default>
      <crud-edit
        :api-get-item-url="'config/entry/' + entryKey"
        :form-path="'config/entry/edit-form/' + entryKey"
        :form-action="'config/entry/' + entryKey"
        :back-route="{ name: 'admin-config' }"
        :success-redirect="{ name: 'admin-config' }"
        @error="handleError">
      </crud-edit>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import CrudEdit from "@/client/components/crud/Edit.vue";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  components: { CrudEdit },
  props: {
    entryKey: {
      type: String,
      default: false,
    },
  },
  setup(props, setupContext) {
    let { t } = useI18n();
    let { meta } = useMeta({
      title: t("admin.config.editView.title"),
    });

    return { meta };
  },
  data: function () {
    return {};
  },
  methods: {
    handleError({ context, data }) {
      console.log("error", context, data);
    },
  },
};
</script>

<style scoped lang="scss"></style>
